<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      branches: [],
      accounts: [],
      Branches: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchModel: "",
      searchBranches: null,
      BranchesSearchMode: false,
      selectedAccounts: null,
      levelThreeAccountId: null,
      levelThreeAccounts: [],
    };
  },
  methods: {
    addAccount(account) {
      this.http
        .post("agents/accounts/subledger", {
          account_id: account?.account_id,
          name: account.name,
          name_en: account?.name_en,
          search: account?.search,
        })
        .then((res) => {
          if (res.status) {
            account.search = "";
            this.get(this.page);
          }
        });
      console.log(account);
    },
    get(page) {
      console.log(page);
      this.http
        .post(
          "level-three-chart-of-accounts-copy-1/accounts-setup/pagination",
          {
            limit: this.limit,
            page: page,
            subledger_id: 3,
          }
        )
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.accounts = res.data;
        });
    },
    selectedAccount(account) {
      let type = "";
      if (account.name_en == "Accounts Payable") {
        type = "Liability";
      }
      if (account.name_en == "Prepayments") {
        type = "Asset";
      }
      this.http
        .post("level-three-chart-of-accounts-copy-1/accounts-setup/search", {
          search: account.search,
          level: 3,
          type,
        })
        .then((res) => {
          if (res.status) {
            this.levelThreeAccounts = res.data.map((a) => {
              return this.$i18n.locale == "ar"
                ? a.code + "-" + a.name
                : a.code + "-" + a.name_en;
            });
          }
        });
    },
    searchAccounts(account) {
      console.log(account);
      this.levelThreeAccountId = account?.search?.split("-")[0];
      console.log(this.levelThreeAccountId);
      this.levelThreeAccounts = [];
      this.http
        .post("level-three-chart-of-accounts-copy-1/accounts-setup", {
          leveThreeId: this.levelThreeAccountId,
          id: account.sas_id,
        })
        .then((res) => {
          if (res.status) {
            console.log("done");
            account.search = "";
            this.get(this.page);
          }
        });
    },
    deleteAccount(index, sub_index, sub_account) {
      if (this.accounts[index]?.sub_accounts[sub_index]?.sas_id) {
        console.log(sub_account);
        var data = {
          title: "popups.delete",
          msg: "popups.deletemsg",
        };
        this.popup.confirm(data).then((res) => {
          if (res) {
            this.http.delete("agents/accounts", sub_account.sas_id).then(() => {
              this.get(this.page);
            });
          }
        });
        return;
      }
      this.accounts[index]?.sub_accounts.splice(sub_index, 1);
    },
    showEditInput(account) {
      account.isEdit = true;
    },
    hideEditInput(account) {
      account.isEdit = false;
    },
    updateSubAccountName(account) {
      console.log(account);
      account.isEdit = false;
      this.http
        .put("agents/accounts", account.sas_id, {
          name: account?.sas_name,
          name_en: account?.sas_name_en,
        })
        .then((res) => {
          if (res.status) {
            this.get(this.page);
          }
        });
    },
  },
  computed: {
    filteredAccounts() {
      return this.levelThreeAccounts.filter((account) => {
        return account;
      });
    },
  },
  created() {
    this.get(1);
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('menu.menuitems.accounting.subItems.setup')" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-centered table-nowrap table-striped table-hover align-middle"
              >
                <thead>
                  <tr
                    class="text-light"
                    style="background-color: #2a3042 !important"
                  >
                    <th scope="col">#</th>
                    <th scope="col">{{ $t("accounts_setup.module") }}</th>
                    <th scope="col">
                      {{ $t("accounts_setup.account_name") }}
                    </th>
                    <th scope="col">
                      {{ $t("accounts_setup.sub_accounts") }}
                    </th>
                    <!-- <th scope="col">
                      {{ $t("accounts_setup.selected_account") }}
                    </th>
                     -->
                    <th scope="col">{{ $t("accounts_setup.operations") }}</th>
                  </tr>
                </thead>
                <tbody style="vertical-align: top">
                  <tr v-for="(account, index) in accounts" :key="account">
                    <td class="pt-4">{{ index + 1 }}</td>
                    <td class="pt-4">
                      <div>
                        {{
                          $i18n.locale == "ar"
                            ? account.ledger_name
                            : account.ledger_name_en
                        }}
                      </div>
                    </td>
                    <td class="pt-4">
                      {{
                        $i18n.locale == "ar"
                          ? account.account_name
                          : account.account_name_en
                      }}
                    </td>
                    <td class="pt-4">
                      <div class="table-responsive">
                        <table
                          class="table table-centered table-nowrap align-middle"
                        >
                          <thead>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                sub_account, sub_index
                              ) in account?.sub_accounts"
                              :key="sub_account"
                            >
                              <td>
                                <div
                                  @dblclick="showEditInput(sub_account)"
                                  v-if="sub_account?.sas_id"
                                  style="cursor: pointer"
                                >
                                  <div v-if="sub_account.isEdit">
                                    <div
                                      type="button"
                                      @click="hideEditInput(sub_account)"
                                    >
                                      <i
                                        class="text-danger float-end"
                                        style="
                                          font-weight: bold;
                                          font-size: medium;
                                        "
                                        >X</i
                                      >
                                    </div>
                                    <div>
                                      <input
                                        type="text"
                                        v-model="sub_account.sas_name"
                                        @change="
                                          updateSubAccountName(sub_account)
                                        "
                                        class="form-control block"
                                      />
                                      <input
                                        type="text"
                                        v-model="sub_account.sas_name_en"
                                        @change="
                                          updateSubAccountName(sub_account)
                                        "
                                        class="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div v-else>
                                    {{
                                      $i18n.locale == "ar"
                                        ? sub_account?.sas_name
                                        : sub_account?.sas_name_en
                                    }}
                                  </div>
                                </div>
                                <div v-else>
                                  <input
                                    v-model="sub_account.name"
                                    type="text"
                                    :placeholder="$t('popups.name_ar')"
                                    class="form-control block"
                                  />
                                  <input
                                    v-model="sub_account.name_en"
                                    type="text"
                                    :placeholder="$t('popups.name_en')"
                                    class="form-control"
                                  />
                                </div>
                              </td>
                              <td>{{ sub_account?.code }}</td>
                              <td>
                                {{
                                  $i18n.locale == "ar"
                                    ? sub_account?.l3_name
                                    : sub_account?.l3_name_en
                                }}
                              </td>
                              <td>
                                <AutoComplete
                                  @keyup="selectedAccount(sub_account)"
                                  v-model="sub_account.search"
                                  :suggestions="filteredAccounts"
                                  @complete="sub_account.search = $event.query"
                                  style="
                                    color: #333 !important;
                                    border-radius: 10px !important;
                                  "
                                />
                                <button
                                  v-if="sub_account?.sas_id"
                                  style="margin-inline-start: 8px"
                                  @click="searchAccounts(sub_account)"
                                  type="button"
                                  class="btn btn-primary"
                                >
                                  {{ $t("popups.edit") }}
                                </button>
                                <button
                                  v-else
                                  style="margin-inline-start: 8px"
                                  @click="addAccount(sub_account)"
                                  type="button"
                                  class="btn btn-success"
                                >
                                  {{ $t("popups.add") }}
                                </button>
                                <button
                                  style="margin-inline-start: 8px"
                                  @click="
                                    deleteAccount(index, sub_index, sub_account)
                                  "
                                  type="button"
                                  class="btn btn-danger"
                                >
                                  <i class="bx bx-trash"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                    <td>
                      <button
                        v-if="account.has_sub"
                        type="button"
                        @click="
                          account?.sub_accounts.push({
                            account_id: account?.account_id,
                          })
                        "
                        class="btn btn-light float-end mb-4"
                      >
                        <span
                          class="bx bxs-plus-circle float-end fa-2x"
                          style="color: #2a3042 !important"
                        ></span>
                      </button>
                    </td>
                    <!-- <td>{{ account.level_three_code }}</td>
                    <td>
                      {{
                        $i18n.locale == "ar"
                          ? account?.level_three_name
                          : account?.level_three_name_en
                      }}
                    </td>
                    <td>
                      <div class="d-flex">
                        <AutoComplete
                          @keyup="selectedAccount(account)"
                          v-model="account.search"
                          :suggestions="filteredAccounts"
                          @complete="account.search = $event.query"
                          style="
                            color: #333 !important;
                            border-radius: 10px !important;
                          "
                        />
                        <button
                          style="margin-inline-start: 8px"
                          @click="searchAccounts(account)"
                          type="button"
                          class="btn btn-primary"
                        >
                          {{ $t("popups.edit") }}
                        </button>
                      </div>
                    </td>
                   -->
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end  table -->
            <!-- Button trigger modal -->

            <!--   Apps  Pagination     -->
            <ul
              class="pagination pagination-rounded justify-content-center mb-2"
            >
              <li class="page-item" :class="{ disabled: page == 1 }">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(page - 1)"
                  aria-label="Previous"
                >
                  <i class="mdi mdi-chevron-left"></i>
                </a>
              </li>
              <li
                class="page-item"
                :class="{ active: p == page }"
                v-for="p in tot_pages"
                :key="p"
              >
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(p)"
                  >{{ p }}</a
                >
              </li>

              <li class="page-item" :class="{ disabled: page == tot_pages }">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(page + 1)"
                  aria-label="Next"
                >
                  <i class="mdi mdi-chevron-right"></i>
                </a>
              </li>
            </ul>
            <!--end-->
          </div>
        </div>
      </div>
    </div>

    <!-- start add account modal -->
    <!-- <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <form @submit.prevent="addEmpLoan()">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("emp_loans.title") }}
            </h5>
            <button
              type="button"
              class="btn-close"
              style="margin: 0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <div class="row d-flex align-items-center">
              <div class="col">
                <label for="asset_id">{{
                  $t("emp_loans.loans_options")
                }}</label>
              </div>
              <div class="col d-flex justify-content-end">
                <button
                  type="button"
                  @click="addAsset.push({})"
                  class="btn float-end"
                >
                  <span
                    class="bx bxs-plus-circle float-end fa-2x"
                    style="color: #2a3042 !important"
                  ></span>
                </button>
              </div>
            </div>
            <div
              style="padding: 1rem; background: #f6f4f4"
              v-for="(asset, index) in addAsset"
              :key="asset"
            >
              <div class="row my-3">
                <div class="d-flex justify-content-between">
                  <select
                    v-model="asset.account"
                    class="form-select mb-2"
                    id="asset_id"
                    :placeholder="$t('emps.account')"
                  >
                    <option
                      :disabled="assets?.balance == 0"
                      :style="{
                        backgroundColor: assets?.balance == 0 ? '#f1f1f1' : '',
                      }"
                      v-for="assets in currentAssets"
                      :key="assets"
                      :value="assets"
                    >
                      {{
                        $i18n.locale == "ar"
                          ? `(${assets?.name}) (${parseFloat(
                              assets?.balance
                            ).toLocaleString()})`
                          : `(${assets?.name_en}) (${parseFloat(
                              assets?.balance
                            ).toLocaleString()})`
                      }}
                    </option>
                  </select>
                
                  <span class="mx-2">
                    <button
                      class="btn btn-danger"
                      @click="deleteOption(index)"
                      v-if="index > 0"
                    >
                      <i class="bx bx-trash"></i>
                    </button>
                    <button v-else disabled class="btn btn-danger">
                      <i class="bx bx-trash"></i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button
              type="submit"
              class="btn btn-primary"
            >
              {{ $t("popups.add") }}
            </button>
            <button
              data-bs-dismiss="modal"
              type="button"
              class="btn btn-danger"
            >
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div> -->
    <!-- end add account modal -->
  </Layout>
</template>
